import { useFormik } from 'formik';
import React, { useMemo } from 'react'
import { updateProfile } from '../../utils/Api';
import * as Yup from "yup";
import clsx from 'clsx';
import { Form, FormSelect } from 'react-bootstrap';
import Autocomplete from "react-google-autocomplete";


const AddressDetails = ({ forward, back, skiper, data }) => {

    const initialValues = useMemo(() => {
        return {
            payment_per_annum: data.payment_per_annum || "Tier-1 Less than 5 times",
            value_per_annum: data.value_per_annum || "Tier-1 Less than $30,000",
            city: data.city || "",
            state: data.state || "",
            postcode: data.postcode || "",
            street: data.street || "",
            flat: data.flat || "",
            building: data.building || "",
            country_code: data.country_code || "AU",
            country: data.country || "Australia",
            address: data.address || "",
            location: data.location || "",
        }
    }, [data]);

    const validationSchema = Yup.object().shape({
        payment_per_annum: Yup.string().required(),
        value_per_annum: Yup.string().required(),
        country: Yup.string().min(2).max(30).required().notOneOf(["none"]),
        state: Yup.string().min(2).max(35).required().trim().notOneOf(["", " "]),
        city: Yup.string().min(1).max(35).required().trim().notOneOf(["", " "]),
        postcode: Yup.string().length(4).required(),
        street: Yup.string().max(100).required().trim().notOneOf(["", " "]),
        flat: Yup.string().max(30).notRequired(),
        building: Yup.string().min(1).max(30).required().trim().notOneOf(["", " "]),
        address: Yup.string(),
    })

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues, setFieldValue, getFieldProps } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            let payload = values;
            payload.location= values.country
            if (/^\s*$/.test(payload.flat)) {
                delete payload['flat']
            }
            if (payload.address === "" || payload.address === undefined || payload.address === " ") {
                delete payload['address'];
            }
            if (values.country === "Australia") {
                payload.country_code = "AU"
            } else {
                payload.country_code = "NZ"
            }
            forward(payload);
            await updateProfile(payload).then(res => {
                if (res.code === "200") {
                    let user = JSON.parse(sessionStorage.getItem("remi-user-dt"))
                    let local = { ...res?.data, is_digital_Id_verified: user?.is_digital_Id_verified }
                    sessionStorage.removeItem("remi-user-dt")
                    sessionStorage.setItem("remi-user-dt", JSON.stringify(local))
                } else if (res.code === "400") {
                    toast.error(res.message, { position: "bottom-right", hideProgressBar: true, autoClose: 2000 })
                }
            }).catch((err) => {
                toast.error(err.message, { position: "bottom-right", autoClose: 2000, hideProgressBar: true })
            })
        },
        validateOnBlur: true,
    });

    const OnCountryChange = (e) => {
        setValues({ ...values, country: e.target.value, state: "", city: "", postcode: "", street: "" })
    }

    const skipHandler = () => {
        handleSubmit(values);
        skiper();
    }


    const getSelectedStreet = async (place) => {
        let country = "", state = "", city = "", postcode = "", street = "", building = "";
        await place?.address_components?.forEach((component) => {
            if (component?.types?.includes('postal_code')) {
                postcode = component?.long_name;
            } else if (component?.types?.includes('route') || component.types.includes('street_name')) {
                street = street + component?.long_name;
            } else if (component?.types?.includes('locality')) {
                city = component?.long_name;
            } else if (component?.types?.includes('administrative_area_level_1')) {
                state = component?.long_name;
            } else if (component?.types?.includes('country')) {
                country = component?.long_name;
            } else if (component?.types?.includes('subpremise') || component?.types?.includes('building') || component?.types?.includes('building_number') || component?.types?.includes('street_number')) {
                building = component?.long_name;
            }
        })

        setFieldValue("country", country)
        setFieldValue("state", state)
        setFieldValue("postcode", postcode)
        setFieldValue("city", city)
        setFieldValue("street", street.trim())
        setFieldValue("building", building)
        setFieldValue("address", place?.formatted_address)
    }

    const handleOnlyAplha = (event) => {
        const result = event.target.value.replace(/[^A-Za-z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/? ]/gi, "");
        event.target.value = result;
        handleChange(event);
    }

    const handleNumericOnly = (event) => {
        const result = event.target.value.replace(/[^0-9]/, "");
        event.target.value = result;
        handleChange(event);
    }

    const handleEmail = (e, max) => {
        if (e.key === 'Backspace' || e.key === 'Enter' || e.key === 'Tab' || e.key === 'Shift' || e.key === 'ArrowLeft' || e.key === "ArrowRight" || e.key === "Escape" || e.key === "Delete") {
            handleChange(e);
        } else {
            const value = e.target.value.toString()
            if (value.length >= max) {
                e.stopPropagation()
                e.preventDefault()

            } else {
                handleChange(e);
            }
        }
    }

    return (
        <section className="kyc">
            <form onSubmit={handleSubmit} noValidate className="single-recipient">
                <div className="">
                    <div className="">
                        <div className="row each-row">
                            <p className="mb-3"><span className="h5">Account Usage</span><span className='small'>&nbsp;(Utilization above tier 1 requires additional verification documents.)</span></p>
                            <div className="col-md-6">
                                <div className="input_field">
                                    <p className="get-text">Projected frequency of payments per annum<span style={{ color: 'red' }} >*</span></p>
                                    <input
                                        type="text"
                                        name="payment_per_anmum"
                                        value={values.payment_per_annum}
                                        disabled
                                        className={clsx(
                                            'form-control bg-transparent',
                                            { 'is-invalid': touched.payment_per_annum && errors.payment_per_annum && values.payment_per_annum !== "" && null },
                                            {
                                                'is-valid': touched.payment_per_annum && !errors.payment_per_annum && values.payment_per_annum !== "" && null,
                                            }
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input_field">
                                    <p className="get-text">Projected value of payments per annum<span style={{ color: 'red' }} >*</span></p>
                                    <input
                                        type="text"
                                        name="value_per_annum"
                                        value={values.value_per_annum}
                                        disabled
                                        className={clsx(
                                            'form-control bg-transparent',
                                            { 'is-invalid': touched.value_per_annum && errors.value_per_annum && values.value_per_annum !== "" && null },
                                            {
                                                'is-valid': touched.value_per_annum && !errors.value_per_annum && values.value_per_annum !== "" && null,
                                            }
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row each-row">
                            <h5>Your Address</h5>
                            <div className="col-md-6 mb-3">
                                <Form.Group className="form_label" controlId="country">
                                    <p className="get-text">Country<span style={{ color: 'red' }} >*</span></p>
                                    <FormSelect
                                        value={values.country}
                                        name="country"
                                        id="country"
                                        onChange={OnCountryChange}
                                        className={clsx(
                                            'bg-transparent',
                                            { 'is-invalid': touched.country && errors.country },
                                            {
                                                'is-valid': touched.country && !errors.country,
                                            }
                                        )}
                                    >
                                        <option value="Australia">Australia</option>
                                        <option valu="New Zealand">New Zealand</option>
                                    </FormSelect>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row each-row">
                            <div className="col-md-12 mb-3">
                                <Form.Group className="form_label" controlId="address">
                                    <p className="get-text">Address</p>
                                    <Autocomplete
                                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                        onPlaceSelected={getSelectedStreet}
                                        placeholder="Street Address, Company or P.O. box.. "
                                        id="address"
                                        name="address"
                                        className="form-control"
                                        options={{
                                            types: [],
                                            componentRestrictions: { country: values.country === "New Zealand" ? "nz" : "au" },
                                        }}
                                        onChange={handleChange}
                                        value={values.address}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row each-row">
                            <div className="col-md-4 mb-3">
                                <Form.Group className="form_label" controlId="flat">
                                    <p className="get-text">Unit/Apt No.</p>
                                    <input
                                        type="text"
                                        name="flat"
                                        value={values.flat}
                                        onKeyDown={(e) => { handleEmail(e, 15) }}
                                        {...getFieldProps("flat")}
                                        className={clsx(
                                            'form-control bg-transparent',
                                            { 'is-invalid': touched.flat && errors.flat && values.flat !== "" && null },
                                            {
                                                'is-valid': touched.flat && !errors.flat && values.flat !== "" && null,
                                            }
                                        )}
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-4 mb-3">
                                <Form.Group className="form_label" controlId="building">
                                    <p className="get-text">Building No.<span style={{ color: 'red' }} >*</span></p>
                                    <input
                                        type="text"
                                        name="building"
                                        value={values.building}
                                        onKeyDown={(e) => { handleEmail(e, 30) }}
                                        {...getFieldProps("building")}
                                        className={clsx(
                                            'form-control bg-transparent',
                                            { 'is-invalid': touched.building && errors.building },
                                            {
                                                'is-valid': touched.building && !errors.building,
                                            }
                                        )}
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-4 mb-3">
                                <Form.Group className="form_label" controlId="street_name">
                                    <p className="get-text">Street Name<span style={{ color: 'red' }} >*</span></p>
                                    <input
                                        type="text"
                                        name="street"
                                        value={values.street}
                                        onKeyDown={(e) => { handleEmail(e, 100) }}
                                        {...getFieldProps("street")}
                                        className={clsx(
                                            'form-control bg-transparent',
                                            { 'is-invalid': touched.street && errors.street },
                                            {
                                                'is-valid': touched.street && !errors.street,
                                            }
                                        )}
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row each-row">
                            <div className="col-md-4 mb-3">
                                <Form.Group className="form_label" controlId="city">
                                    <p className="get-text">City/Suburb<span style={{ color: 'red' }} >*</span></p>
                                    <input
                                        type="text"
                                        name="city"
                                        value={values.city}
                                        maxLength="35"
                                        onChange={handleOnlyAplha}
                                        className={clsx(
                                            'form-control bg-transparent',
                                            { 'is-invalid': touched.city && errors.city },
                                            {
                                                'is-valid': touched.city && !errors.city,
                                            }
                                        )}
                                        placeholder="city or suburb .."
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-4 mb-3">
                                <Form.Group className="form_label" controlId="postal">
                                    <p className="get-text">Zip/Postal Code<span style={{ color: 'red' }} >*</span></p>
                                    <input
                                        type="text"
                                        name="postcode"
                                        value={values.postcode}
                                        maxLength="4"
                                        onChange={handleNumericOnly}
                                        className={clsx(
                                            'form-control bg-transparent',
                                            { 'is-invalid': touched.postcode && errors.postcode },
                                            {
                                                'is-valid': touched.postcode && !errors.postcode,
                                            }
                                        )}
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-4 mb-3">
                                <Form.Group className="form_label statess" controlId="state">
                                    <p className="get-text">State<span style={{ color: 'red' }} >*</span></p>
                                    <input
                                        type="text"
                                        name="state"
                                        value={values.state}
                                        maxLength="30"
                                        onChange={handleOnlyAplha}
                                        className={clsx(
                                            'form-control bg-transparent',
                                            { 'is-invalid': touched.state && errors.state },
                                            {
                                                'is-valid': touched.state && !errors.state,
                                            }
                                        )}
                                        placeholder="state or province .."
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="next-step">
                    <button type="button" className="SKip back-btn" onClick={() => back()}>Back</button>
                    <button type="submit" className="login_button">Continue<img src="assets/img/home/Union.png" className="vission_image" alt="alt_image" /></button>
                    <button type="button" className="SKip" onClick={() => skipHandler()}>Skip</button>
                </div>
            </form>
        </section>
    )
}

export default AddressDetails