import React, { useEffect, useMemo, useState } from 'react';
import BusinessDetails from './BusinessDetails';
import PersonalDetail from './PersonalDetail';
import { useNavigate } from 'react-router';
import { userProfile } from '../../utils/Api';
import VeriffComp from './VeriffComp';
import FinalStep from './FinalStep';
import AddressDetails from './AddressDetails';

const ParentComp = ({ is_model, handleModel, currentStep }) => {

    // ------------ navigator setup
    const navigate = useNavigate()

    //  -------------- Steps states
    const [completedSteps, setCompletedSteps] = React.useState([]);
    const [activeStep, setActiveStep] = React.useState(1);
    const [veriff_status, setVeriffStatus] = React.useState(null)

    // ---------------- Data state
    const [formData, setData] = useState({
        business_name: '',
        bussiness_nature: '',
        registration_date: '',
        registration_number: '',
        registration_country: '',
        business_address: '',
        First_name: '',
        Middle_name: '',
        Last_name: '',
        email: '',
        mobile: '',
        Date_of_birth: "",
        Country_of_birth: "none",
        location: "",
        occupation: "",
        payment_per_annum: "Tier-1 Less than 5 times",
        value_per_annum: "Tier-1 Less than $30,000",
        city: "",
        state: "",
        postcode: "",
        street: "",
        flat: "",
        building: "",
        country_code: "AU",
        country: "Australia",
        address: "",
    });

    const [initial_contact, setInitialContact] = useState({ mobile: "", email: "", areacode: "" })
    const [account_type, setAccountType] = useState(JSON.parse(sessionStorage.getItem("remi-user-dt"))?.account_type || "individual");
    const [is_digital_Id_verified, setIsDigitalIdVerified] = useState(null);


    // ------------ Fetch existing data from the database 
    useEffect(() => {
        if (!sessionStorage.getItem("token") && !sessionStorage.getItem("remi-user-dt")) {
            navigate("/login")
        } else {
            let dt = JSON.parse(sessionStorage.getItem("remi-user-dt"))
            if (dt.is_digital_Id_verified?.toString()?.toLowerCase() !== "declined" && dt.is_digital_Id_verified?.toString()?.toLowerCase() !== "pending" && dt.profile_completed == true) {
                navigate("/dashboard")
            }

            const user_d = JSON.parse(sessionStorage.getItem("remi-user-dt"))

            if (currentStep && currentStep == 3) {
                if (user_d.account_type == "business") {
                    setCompletedSteps([1, 2, 3]);
                    setActiveStep(4)
                } else {
                    setCompletedSteps([1, 2]);
                    setActiveStep(3)
                }
            }
        }
        userProfile().then((res) => {
            if (res.code == "200") {
                let phone = res?.data.mobile.substring(3);

                setData({
                    ...res.data, mobile: phone, country: res.data.location
                })
                setIsDigitalIdVerified(res.data.is_digital_Id_verified)
                setAccountType(res.data.account_type)
                setInitialContact({ mobile: res.data.mobile, email: res.data.email, areacode: res.data.mobile.substring(1, 3) })
            }
        }).catch((error) => {
            if (error.response?.data?.code == "400") {
                toast.error(error.response.data.message, { position: "bottom-right", autoClose: 2000, hideProgressBar: true })
            }
        })
    }, [])

    // step navigating and handling
    const nextStep = (values) => {
        if (activeStep == 2 && (is_digital_Id_verified == "approved" || is_digital_Id_verified == "submitted")) {
            endHandler()
        } else {
            setCompletedSteps([...completedSteps, activeStep]);
            setActiveStep(activeStep + 1);
        }
        if (values) {
            setData({ ...formData, ...values })
        }

    };

    const prevStep = () => {
        setCompletedSteps([...completedSteps, activeStep]);
        setActiveStep(activeStep - 1);
    };

    const endHandler = () => {
        if (is_model) {
            handleModel()
        } else {
            navigate("/dashboard")
        }
    }

    const skip = () => {
        endHandler()
    }

    return (
        <section className="sigupsec" >
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-8">
                        <div style={{ marginBottom: '20px' }}>
                            <ul id="progressbar">
                                {
                                    account_type === "business" ?
                                        [1, 2, 3, 4, 5].map((step) => (
                                            <li
                                                style={{ width: "20%" }}
                                                key={step}
                                                className={`step ${step === activeStep ? 'active' : ''} ${completedSteps.includes(step) ? 'done' : ''}`}
                                            >
                                                Step {step}
                                            </li>
                                        ))
                                        :
                                        [1, 2, 3, 4].map((step) => (
                                            <li
                                                key={step}
                                                style={{ width: "25%" }}
                                                className={`step ${step === activeStep ? 'active' : ''} ${completedSteps.includes(step) ? 'done' : ''}`}
                                            >
                                                Step {step}
                                            </li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="row align-center1">
                            <div className="col-lg-5">
                                <div className="kyc-img">
                                    <img src="assets/img/home/kyc.webp" className="signup" alt="alt_image" />
                                </div>
                            </div>
                            <div className="col-lg-7 d-flex align-items-center">
                                <div>
                                    <h2 className="Sign-heading my-3">KYC</h2>
                                    <h3 className='sub-head'>Complete your KYC in {account_type == "business" ? "5" : "4"} steps</h3>
                                    <div className='steps-form'>
                                        {
                                            account_type == "business" ? (
                                                <>
                                                    {activeStep == 1 && <BusinessDetails data={formData} forward={nextStep} skiper={skip} />}
                                                    {activeStep == 2 && <PersonalDetail accountType={account_type} data={formData} forward={nextStep} skiper={skip} back={prevStep} initialContact={initial_contact} />}
                                                    {activeStep == 3 && <AddressDetails data={formData} forward={nextStep} skiper={skip} back={prevStep} />}
                                                    {activeStep == 4 && <VeriffComp forward={nextStep} values={formData} setVeriffStatus={setVeriffStatus} />}
                                                    {activeStep == 5 && <FinalStep end_handler={endHandler} veriff_status={veriff_status} />}
                                                </>
                                            ) : (
                                                <>
                                                    {activeStep == 1 && <PersonalDetail accountType={account_type} data={formData} forward={nextStep} skiper={skip} initialContact={initial_contact} />}
                                                    {activeStep == 2 && <AddressDetails data={formData} forward={nextStep} skiper={skip} back={prevStep} />}
                                                    {activeStep == 3 && <VeriffComp forward={nextStep} values={formData} setVeriffStatus={setVeriffStatus} />}
                                                    {activeStep == 4 && <FinalStep end_handler={endHandler} veriff_status={veriff_status} />}
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ParentComp