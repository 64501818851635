// Second KYC step for Account Type -> Depends //
import React, { useEffect, useMemo, useState } from 'react';
import countryOptions from '../../utils/AllCountryList';
import { senderAreaList as areaList } from "../../utils/ArealList";
import { useFormik } from 'formik';
import * as Yup from "yup";
import clsx from 'clsx';
import { updateProfile } from '../../utils/Api';

const PersonalDetail = ({ data, accountType, initialContact, forward, back, skiper }) => {

    const [selected_area_code, setSelectedAreaCode] = useState(initialContact?.areaCode || "61")

    // Setting initial form data from fetched data
    const [initial, setInitial] = useState({

        ubo_name: data?.ubo_name || '',
        First_name: data?.First_name || '',
        Middle_name: data?.Middle_name || '',
        Last_name: data?.Last_name || '',
        email: data?.email || '',
        mobile: data?.mobile || '',
        Date_of_birth: data?.Date_of_birth || '',
        occupation: data?.occupation || '',
        Country_of_birth: data?.Country_of_birth || ''
    })



    // Yup validation schema for Personal Details
    const personalSchema = Yup.object().shape({
        ubo_name: accountType == "business" ? Yup.string().min(3).max(25).required('U.B.O Name is required').trim().notOneOf([" "]) : Yup.string().notRequired(),
        First_name: Yup.string().min(1).max(25).required().trim(),
        Middle_name: Yup.string().max(25).trim(),
        Last_name: Yup.string().min(1).max(25).required().trim(),
        email: Yup.string().matches(/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,5}$/, "Invalid email format").max(50).required(),
        mobile: Yup.string().min(9).max(10).matches(/^[0-9]{9,10}/, "Invalid mobile number").required(),
        Date_of_birth: Yup.date().min(new Date(Date.now() - 3721248000000), "Must be atleast 18 year old").max(new Date(Date.now() - 567648000000), "Must be atleast 18 year old").required("DOB is required"),
        occupation: Yup.string().min(1).max(50).required().trim(),
        Country_of_birth: Yup.string().required().notOneOf(["none"]),
    })

    // formik setup for Personal Details
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue, setValues } = useFormik({
        initialValues: initial,
        validationSchema: personalSchema,
        onSubmit: async (values) => {
            let payload = values;
            let mobile = parseInt(values.mobile, 10)
            if (initialContact.mobile !== "+" + selected_area_code + mobile) {
                payload.mobile = "+" + selected_area_code + mobile
            } else {
                delete payload["mobile"]
            }
            if (initialContact.email === values.email) {
                delete payload["email"]
            }
            if (/^\s*$/.test(payload.Middle_name)) {
                delete payload['Middle_name']
            }

            if (accountType !== "business") {
                delete payload['ubo_name']
            }
            forward(payload);
            await updateProfile(payload).then(res => {
                if (res.code === "200") {
                    let user = JSON.parse(sessionStorage.getItem("remi-user-dt"))
                    let local = { ...res?.data, is_digital_Id_verified: user?.is_digital_Id_verified }
                    sessionStorage.removeItem("remi-user-dt")
                    sessionStorage.setItem("remi-user-dt", JSON.stringify(local))
                } else if (res.code === "400") {
                    toast.error(res.message, { position: "bottom-right", hideProgressBar: true, autoClose: 2000 })
                }
            }).catch((err) => {
                toast.error(err.message, { position: "bottom-right", autoClose: 2000, hideProgressBar: true })
            })
        }
    });

    useEffect(() => {
        if (initialContact) {
            setSelectedAreaCode(initialContact.areacode || "61")
        }
        setValues({
            ubo_name: data?.ubo_name || '',
            First_name: data?.First_name || '',
            Middle_name: data?.Middle_name || '',
            Last_name: data?.Last_name || '',
            email: data?.email || '',
            mobile: data?.mobile || '',
            Date_of_birth: data?.Date_of_birth || '',
            occupation: data?.occupation || '',
            Country_of_birth: data?.Country_of_birth || '',
        })
    }, [data, initialContact])

    // function to handle only alphabetic values excludes [digits and special characters];
    const handleOnlyAplha = (e) => {
        let regex = /^[a-zA-Z\s]+$/;
        if (!regex.test(e.target.value)) {
            e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
        }
        handleChange(e);
    }

    const onKeyBirth = (event) => {
        if (values?.Date_of_birth !== "" || null || undefined) {
            if (event.key === " ") {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    const handleEmail = (e, max) => {
        if (e.key === 'Backspace' || e.key === 'Enter' || e.key === 'Tab' || e.key === 'Shift' || e.key === 'ArrowLeft' || e.key === "ArrowRight" || e.key === "Escape" || e.key === "Delete") {
            setFieldValue(`${[e.target.name]}`, e.target.value)
            setFieldTouched(`${[e.target.name]}`, true)
        } else {
            const value = e.target.value.toString()
            if (value.length >= max) {
                e.stopPropagation()
                e.preventDefault()

            } else {
                setFieldValue(`${[e.target.name]}`, e.target.value)
                setFieldTouched(`${[e.target.name]}`, true)
            }
        }
    }

    // function to handle Skip;
    const handleSkip = () => {
        handleSubmit(values);
        skiper();
    }

    //function to handle numberic values
    const handleNumericOnly = (event) => {
        const result = event.target.value.replace(/[^0-9]/, "");
        event.target.value = result;
        handleChange(event)
    }

    // Date validation for DOB
    useEffect(() => {
        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var day;
        if (month === 2 && dtToday.getDate() === 29) {
            day = dtToday.getDate() - 1
        } else {
            day = dtToday.getDate();
        }
        var year = dtToday.getFullYear() - 18;
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();
        var maxDate = year + '-' + month + '-' + day;
        var minDate = year - 100 + '-' + month + "-" + day
        document.getElementById("dob").setAttribute('max', maxDate);
        document.getElementById("dob").setAttribute('min', minDate);
    }, [])

    return (
        <div>
            <section className="kyc">
                <form onSubmit={handleSubmit} noValidate className="single-recipient">
                    <div className="row each-row">
                        <h5>Personal Details</h5>
                        {
                            accountType == "business" && (
                                <div className="col-md-6">
                                    <div className="input_field">
                                        <p className="get-text">Name of UBO <small>(with over 10% shareholding)</small><span style={{ color: 'red' }} >*</span></p>
                                        <input
                                            type="text"
                                            name="ubo_name"
                                            id="ubo_name"
                                            value={values.ubo_name}
                                            onChange={handleOnlyAplha}
                                            maxLength="30"
                                            className={clsx(
                                                'form-control bg-transparent',
                                                { 'is-invalid': touched.ubo_name && errors.ubo_name },
                                                { 'is-valid': touched.ubo_name && !errors.ubo_name }
                                            )}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <div className='row'>
                        {
                            accountType == "business" &&
                            <h5>Authorised Business Represntative</h5>
                        }
                        <div className="col-md-4">
                            <div className="input_field">
                                <p className="get-text">First Name<span style={{ color: 'red' }} >*</span></p>
                                <input
                                    type="text"
                                    name="First_name"
                                    value={values.First_name}
                                    onChange={handleOnlyAplha}
                                    maxLength="25"
                                    className={clsx(
                                        'form-control bg-transparent',
                                        { 'is-invalid': touched.First_name && errors.First_name },
                                        { 'is-valid': touched.First_name && !errors.First_name }
                                    )}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="input_field">
                                <p className="get-text">Middle Name</p>
                                <input
                                    type="text"
                                    name="Middle_name"
                                    className='form-control'
                                    maxLength="25"
                                    onChange={handleOnlyAplha}
                                    value={values.Middle_name}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="input_field">
                                <p className="get-text">Last Name<span style={{ color: 'red' }} >*</span></p>
                                <input
                                    type="text"
                                    name="Last_name"
                                    value={values.Last_name}
                                    maxLength="25"
                                    onChange={handleOnlyAplha}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        { 'is-invalid': touched.Last_name && errors.Last_name },
                                        { 'is-valid': touched.Last_name && !errors.Last_name, }
                                    )}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="row each-row">
                        <div className="col-md-6">
                            <div className="input_field">
                                <p className="get-text">Email<span style={{ color: 'red' }} >*</span></p>
                                <input
                                    type="email"
                                    name='email'
                                    value={values.email}
                                    style={{ backgroundColor: "rgba(252, 253, 255, 0.81)" }}
                                    onKeyDown={(e) => { handleEmail(e, 50) }}
                                    onChange={handleChange}
                                    className={clsx('form-control bg-transparent',
                                        { 'is-invalid': touched.email && errors.email },
                                        {
                                            'is-valid': touched.email && !errors.email,
                                        })}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input_field">
                                <p className="get-text">Mobile<span style={{ color: 'red' }} >*</span></p>
                                <div className="row kustom_mobile">
                                    <div className="col-md-4 px-0">
                                        <select
                                            className="form-control form-select bg-transparent"
                                            value={selected_area_code}
                                            onChange={(e) => setSelectedAreaCode(e.target.value)}
                                        >
                                            {
                                                areaList?.map((area, index) => {
                                                    return (
                                                        <option key={index} value={area?.code}>+{area?.code}&nbsp;({area?.name})</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className={`col-md-8 px-0`}>
                                        <input
                                            type="text"
                                            name="mobile"
                                            value={values.mobile}
                                            id="mobile"
                                            maxLength="10"
                                            minLength='9'
                                            onChange={(e) => handleNumericOnly(e)}
                                            onBlur={handleBlur}
                                            className={clsx(
                                                'form-control bg-transparent',
                                                { 'is-invalid': touched.mobile && errors.mobile },
                                                {
                                                    'is-valid': touched.mobile && !errors.mobile,
                                                }
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row each-row">
                        <div className="col-md-4">
                            <div className="input_field">
                                <p className="get-text">Date of Birth<span style={{ color: 'red' }} >*</span></p>
                                <input
                                    type="date"
                                    name="Date_of_birth"
                                    value={values.Date_of_birth}
                                    id="dob"
                                    onChange={(e) => handleChange(e)}
                                    onKeyDown={(event) => { onKeyBirth(event) }}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        { 'is-invalid': touched.Date_of_birth && errors.Date_of_birth },
                                        {
                                            'is-valid': touched.Date_of_birth && !errors.Date_of_birth,
                                        }
                                    )}
                                    onBlur={handleBlur}
                                />
                                {touched.Date_of_birth && errors.Date_of_birth && (
                                    <div className='fv-plugins-message-container mt-1'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className="text-danger">{errors.Date_of_birth}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 country-h">
                            <div className="input_field">
                                <p className="get-text">Country of Birth<span style={{ color: 'red' }} >*</span></p>
                                <select
                                    value={values.Country_of_birth}
                                    name="Country_of_birth"
                                    onChange={(e) => handleChange(e)}
                                    className={clsx(
                                        'form-control form-select bg-transparent',
                                        { 'is-invalid': touched.Country_of_birth && errors.Country_of_birth },
                                        {
                                            'is-valid': touched.Country_of_birth && !errors.Country_of_birth,
                                        }
                                    )}
                                    onBlur={handleBlur}
                                >
                                    <option value={"none"} key={"none"}>Select a country</option>
                                    {
                                        countryOptions && countryOptions.length > 0 ?
                                            countryOptions?.map((opt) => {
                                                return (
                                                    <option value={opt.name}>{opt.name}</option>
                                                )
                                            }) : ""
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="input_field">
                                <p className="get-text">Occupation<span style={{ color: 'red' }} >*</span></p>
                                <input
                                    type="text"
                                    name="occupation"
                                    value={values.occupation}
                                    id="occupation"
                                    maxLength="50"
                                    onChange={(e) => handleOnlyAplha(e)}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        { 'is-invalid': touched.occupation && errors.occupation },
                                        {
                                            'is-valid': touched.occupation && !errors.occupation,
                                        }
                                    )}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="next-step">
                        {
                            accountType == "business" ? (
                                <>
                                    <button type="button" className="SKip back-btn" onClick={() => back()}>Back</button>
                                    <button type="submit" className="login_button">Continue  <img src="assets/img/home/Union.png" className="vission_image" alt="alt_image" /></button>

                                </>
                            ) : (
                                <button type="submit" className="login_button">Next <b>Step</b>  <img src="assets/img/home/Union.png" className="vission_image" alt="alt_image" /></button>
                            )
                        }
                        <button type="button" onClick={() => handleSkip()} className="SKip">Skip</button>
                    </div>
                </form>
            </section>
        </div>
    )
}

export default PersonalDetail