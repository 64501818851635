// First KYC step for Account Type -> Business //
import React, { useEffect, useMemo, useState } from 'react';
import countryOptions from '../../utils/AllCountryList';
import { useFormik } from 'formik';
import * as Yup from "yup";
import clsx from 'clsx';
import Autocomplete from "react-google-autocomplete";
import { updateProfile } from '../../utils/Api';

const BusinessDetails = ({ data, forward, skiper }) => {

    // Setting initial form data from fetched data
    const [initial, setInitial] = useState({
        business_name: data?.business_name || '',
        business_nature: data?.business_nature || '',
        registration_number: data?.registration_number || '',
        registration_date: data?.registration_date || '',
        registration_country: data?.registration_country || '',
        business_address: data?.business_address || ''
    })
    // Yup validation schema for Business Details
    const businessSchema = Yup.object().shape({
        business_name: Yup.string().min(5).max(50).required('Business Name is required').trim().notOneOf([" "]),
        business_nature: Yup.string().min(5).max(20).required('Business Nature is required').trim().notOneOf([" "]),
        registration_number: Yup.string().min(5).max(18).required('Registration Number is required'),
        registration_date: Yup.date().required('Registration Date is required'),
        registration_country: Yup.string().max(25).required('Registration Country is required'),
        business_address: Yup.string().max(300).required('Business Address is required').trim().notOneOf([" "])
    })

    const [max_registration_date, setMaxRegistrationDate] = useState("")

    // formik setup for Business Details
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initial,
        validationSchema: businessSchema,
        onSubmit: async (values) => {
            forward(values);
            await updateProfile(values).then(res => {
                if (res.code === "200") {
                    let user = JSON.parse(sessionStorage.getItem("remi-user-dt"))
                    let local = { ...res?.data, is_digital_Id_verified: user?.is_digital_Id_verified }
                    sessionStorage.removeItem("remi-user-dt")
                    sessionStorage.setItem("remi-user-dt", JSON.stringify(local))
                } else if (res.code === "400") {
                    toast.error(res.message, { position: "bottom-right", hideProgressBar: true, autoClose: 2000 })
                }
            }).catch((err) => {
                toast.error(err.message, { position: "bottom-right", autoClose: 2000, hideProgressBar: true })
            })
        }
    });

    // function to handle only alphabetic values excludes [digits and special characters];
    const handleOnlyAplha = (e) => {
        let regex = /^[a-zA-Z\s]+$/;
        if (!regex.test(e.target.value)) {
            e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
        }
        handleChange(e);
    }

    // function to handle only alpha-numeric values excludes [space and special characters];
    const handleAlphanumeric = (e) => {
        let regex = /^[a-zA-Z0-9]+$/;
        if (!regex.test(e.target.value)) {
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
        }
        handleChange(e);
    }

    const handleAlphanumericWithSpace = (e) => {
        let regex = /^[a-zA-Z0-9\s]+$/;
        if (!regex.test(e.target.value)) {
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
        }
        handleChange(e);
    }

    // function to handle Skip;
    const handleSkip = () => {
        handleSubmit(values)
        skiper();
    }


    useEffect(() => {
        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var day;
        if (month === 2 && dtToday.getDate() === 29) {
            day = dtToday.getDate() - 1
        } else {
            day = dtToday.getDate();
        }
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();
        setMaxRegistrationDate(year + '-' + month + '-' + day);
    }, [])

    return (
        <div>
            <section className="kyc">
                <form onSubmit={handleSubmit} noValidate className="single-recipient">
                    <div className="row each-row">
                        <h5>Business Details</h5>
                        <div className="col-md-6">
                            <div className="input_field">
                                <p className="get-text">Name of business<span style={{ color: 'red' }} >*</span></p>
                                <input
                                    type="text"
                                    name="business_name"
                                    id="business_name"
                                    value={values.business_name}
                                    onChange={handleAlphanumericWithSpace}
                                    maxLength="50"
                                    className={clsx(
                                        'form-control bg-transparent',
                                        { 'is-invalid': touched.business_name && errors.business_name },
                                        { 'is-valid': touched.business_name && !errors.business_name }
                                    )}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input_field">
                                <p className="get-text">Nature of business<span style={{ color: 'red' }} >*</span></p>
                                <input
                                    type="text"
                                    name="business_nature"
                                    id="business_nature"
                                    value={values.business_nature}
                                    onChange={handleOnlyAplha}
                                    maxLength="30"
                                    className={clsx(
                                        'form-control bg-transparent',
                                        { 'is-invalid': touched.business_nature && errors.business_nature },
                                        { 'is-valid': touched.business_nature && !errors.business_nature }
                                    )}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input_field">
                                <p className="get-text">Registration number<span style={{ color: 'red' }} >*</span></p>
                                <input
                                    type="text"
                                    name="registration_number"
                                    id="registration_number"
                                    value={values.registration_number}
                                    onChange={handleAlphanumeric}
                                    maxLength="30"
                                    className={clsx(
                                        'form-control bg-transparent',
                                        { 'is-invalid': touched.registration_number && errors.registration_number },
                                        { 'is-valid': touched.registration_number && !errors.registration_number }
                                    )}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input_field">
                                <p className="get-text">Registration date<span style={{ color: 'red' }} >*</span></p>
                                <input
                                    type="date"
                                    name="registration_date"
                                    id="registration_date"
                                    value={values.registration_date}
                                    onChange={(e) => handleChange(e)}
                                    max={max_registration_date}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        { 'is-invalid': touched.registration_date && errors.registration_date },
                                        { 'is-valid': touched.registration_date && !errors.registration_date }
                                    )}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input_field">
                                <p className="get-text">Registration country<span style={{ color: 'red' }} >*</span></p>
                                <select
                                    value={values.registration_country}
                                    name="registration_country"
                                    onChange={(e) => handleChange(e)}
                                    className={clsx(
                                        'form-control form-select bg-transparent',
                                        { 'is-invalid': touched.registration_country && errors.registration_country },
                                        {
                                            'is-valid': touched.registration_country && !errors.registration_country,
                                        }
                                    )}
                                    onBlur={handleBlur}
                                >
                                    <option value={"none"} key={"none"}>Select a country</option>
                                    {
                                        countryOptions && countryOptions.length > 0 ?
                                            countryOptions?.map((opt) => {
                                                return (
                                                    <option key={opt.code} value={opt.name}>{opt.name}</option>
                                                )
                                            }) : ""
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="input_field">
                                <p className="get-text">Business address<span style={{ color: 'red' }} >*</span></p>
                                <Autocomplete
                                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                    placeholder="Street Address, Company or P.O. box.. "
                                    id="business_address"
                                    name="business_address"
                                    onBlur={handleBlur}
                                    className={
                                        clsx('form-control bg-transparent',
                                            { 'is-invalid': touched.business_address && errors.business_address },
                                            {
                                                'is-valid': touched.business_address && !errors.business_address
                                            }
                                        )
                                    }
                                    options={{
                                        types: [],
                                        componentRestrictions: { country: countryOptions.find(country => country.name === values?.registration_country)?.code?.toLowerCase() || "au" },
                                    }}
                                    onPlaceSelected={(e) => handleChange({ target: { name: "business_address", value: e.formatted_address } })}
                                    onChange={handleChange}
                                    value={values.business_address}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="next-step">
                        <button type="submit" className="login_button">Next <b>Step</b>  <img src="assets/img/home/Union.png" className="vission_image" alt="alt_image" /></button>
                        <button type="button" onClick={() => handleSkip()} className="SKip">Skip</button>
                    </div>
                </form>
            </section>
        </div>
    )
}

export default BusinessDetails